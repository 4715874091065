
@font-face {
  font-family: "Abril Fatface";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/AbrilFatface-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Alfa Slab One";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/AlfaSlabOne-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Bebas Neue";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/BebasNeue-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Binggrae";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/Binggrae-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Binggrae";
  
  
  font-stretch: 100%;
  src: url("/fonts/Binggrae.ttf") format("truetype");
}
@font-face {
  font-family: "Binggrae Ⅱ";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/BinggraeⅡ-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Binggrae Ⅱ";
  
  
  font-stretch: 100%;
  src: url("/fonts/BinggraeⅡ.ttf") format("truetype");
}
@font-face {
  font-family: "Blinker";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/Blinker-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Blinker";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/Blinker-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Caveat";
  
  
  font-stretch: 100%;
  src: url("/fonts/Caveat-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "GenJyuuGothic";    
  font-stretch: 100%;
  src: url("/fonts/GenJyuuGothicL-Normal.ttf") format("truetype");
}
@font-face {
  font-family: "Gmarket Sans";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/GmarketSansTTFBold.ttf") format("truetype");
}
@font-face {
  font-family: "KyrillaSansSerif";
  font-stretch: 100%;
  src: url("/fonts/KyrillaSansSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "MiSans";
  font-stretch: 100%;
  src: url("/fonts/MiSans-Normal.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  font-style: Italic;
  
  font-stretch: 100%;
  src: url("/fonts/Montserrat-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Montserrat";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Nanum Barunpen";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/NanumBarunpenR.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 900;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Black.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 200;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-DemiLite.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 300;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 500;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans CJK";
  
  font-weight: 100;
  font-stretch: 100%;
  src: url("/fonts/NotoSansCJKkr-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Noto Sans Regular";  
  font-stretch: 100%;
  src: url("/fonts/NotoSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Orbitron";
  
  
  font-stretch: 100%;
  src: url("/fonts/Orbitron-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Pinyon Script";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/PinyonScript-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-Bold.woff2") format("woff2"),
       url("/fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-BoldItalic.woff2") format("woff2"),
       url("/fonts/Poppins-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-SemiBold.woff2") format("woff2"),
       url("/fonts/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-SemiBoldItalic.woff2") format("woff2"),
       url("/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-Medium.woff2") format("woff2"),
       url("/fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-MediumItalic.woff2") format("woff2"),
       url("/fonts/Poppins-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-Regular.woff2") format("woff2"),
       url("/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/Poppins/Poppins-Italic.woff2") format("woff2"),
       url("/fonts/Poppins-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Resource Han Rounded CN";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/ResourceHanRoundedCN-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Resource Han Rounded CN";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/ResourceHanRoundedCN-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SCDream 4";
  
  
  font-stretch: 100%;
  src: url("/fonts/SCDream4.otf") format("opentype");
}
@font-face {
  font-family: "Source Serif Pro";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/SourceSerifPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Source Serif Pro";
  font-style: Italic;
  
  font-stretch: 100%;
  src: url("/fonts/SourceSerifPro-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Source Serif Pro";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/SourceSerifPro-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Swei Spring CJKsc";
  
  font-weight: 700;
  font-stretch: 100%;
  src: url("/fonts/SweiSpringCJKsc-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Swei Spring CJKsc";
  
  font-weight: 400;
  font-stretch: 100%;
  src: url("/fonts/SweiSpringCJKsc-Regular.ttf") format("truetype");
}