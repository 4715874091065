.split-view-view {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

#recordingAudioPlayer {
    border-radius: 0;
    outline: none;
}

#recordingAudioPlayer:focus {
    outline: none;
}
